import './layout.less'
import Header from './header'
import React from 'react'
import labGlassImage from '../images/labglass.svg'
import { Provider, SSRProvider, defaultTheme } from '@adobe/react-spectrum'
import { graphql, useStaticQuery } from 'gatsby'

interface LayoutProps {
    children: any
}

function showConsentPopup() {
    if (window['adobePrivacy']) {
        adobePrivacy.showConsentPopup()
    }
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { children } = props
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    const currentYear = new Date().getFullYear()
    return (
        <SSRProvider>
            <Provider theme={defaultTheme} colorScheme="light" id="appProvider">
                <Header siteTitle={data.site.siteMetadata.title} />
                {children}
                <div className="footer">
                    <div className="content">
                        <div
                            className="statusnote"
                            style={{
                                backgroundImage: 'url(' + labGlassImage + ')',
                            }}
                        >
                            Technologies showcased on Adobe Labs are subject to
                            change, and Adobe makes no commitment that these
                            technologies will be made commercially available.
                        </div>

                        <div className="links">
                            Your use of the Adobe Labs site is governed by the
                            <span> </span>
                            <a
                                href="https://www.adobe.com/legal/terms.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Adobe Terms of Use
                            </a>
                            .<br />
                            <a
                                href="https://www.adobe.com/privacy.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                            <span> | </span>
                            <a
                                id="openCookieModal"
                                href="#"
                                onClick={showConsentPopup}
                            >
                                Cookie preferences
                            </a>
                        </div>
                        <div className="copyright">
                            Copyright © {currentYear} Adobe. All rights
                            reserved.
                        </div>
                    </div>
                </div>
            </Provider>
        </SSRProvider>
    )
}

export default Layout
