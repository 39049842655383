import './header.less'
import LogoImg from '../images/logo.svg'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { isProd, isSSR } from '../utils/isEnv'

interface HeaderProps {
    siteTitle: string
}

const Header: React.FC<HeaderProps> = () => (
    <header>
        <Helmet>
            <link rel="stylesheet" href="https://use.typekit.net/cwx1pba.css" />
            <link
                rel="icon"
                href="https://www.adobe.com/favicon.ico"
                type="image/x-icon"
            />
            <link
                rel="shortcut icon"
                href="https://www.adobe.com/favicon.ico"
                type="image/x-icon"
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
            />

            {isSSR() && (
                <script type="text/javascript">{`
                    window.marketingtech = {
                        adobe: {
                            launch: {
                                property: 'global',
                                environment: ${
                                    isProd() ? "'production'" : "'stage'"
                                }
                            },
                            analytics: {
                                additionalAccounts: '' // additional report suites to send data to “,” separated  Ex: 'RS1,RS2'
                            },
                            target: true,    // if target needs to be enabled else false
                            audienceManager: true    // if audience manager needs to be enabled else false
                        }
                    }
                `}</script>
            )}
            {isSSR() && (
                <script src="https://www.adobe.com/marketingtech/main.min.js"></script>
            )}

            {isSSR() && (
                <script type="text/javascript">{`
                    window.fedsConfig = window.fedsConfig || {};
                    window.fedsConfig.privacy = window.fedsConfig.privacy || {};
                    window.fedsConfig.privacy.otDomainId = '7a5eb705-95ed-4cc4-a11d-0cc5760e93db${
                        isProd() ? '' : '-test'
                    }';
                `}</script>
            )}
            {isSSR() &&
                (isProd() ? (
                    <script src="https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js"></script>
                ) : (
                    <script src="https://www.dev02.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js"></script>
                ))}
        </Helmet>

        <div className="logo">
            <Link to="/">
                <img src={LogoImg} /> Adobe Labs
            </Link>
        </div>
    </header>
)

export default Header
